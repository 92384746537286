import React, { useEffect } from "react";
import { Form } from "../styled";
import moment from 'moment';

interface Props {
    setselectedMonth: Function;
    setselectedDay: Function;
    setSelectedTime: Function;
    setVerification: Function;
    setCustomerName:Function;
    setEmail:Function;
    selectedMonth: string;
    selectedDay: string;
    selectedTime: string;
    months: Array<number>;
    days: Array<number>;
    times: Array<string>;
    this_year: string;
    valid: boolean;
    defaultValue:{
        customerName:string;
        email: string;
    };
}

const Reservation = ({
    setselectedMonth,
    setselectedDay,
    setSelectedTime,
    setVerification,
    setCustomerName,
    setEmail,
    defaultValue,
    months,
    days,
    times,
    this_year,
    selectedDay,
    selectedMonth,
    selectedTime,
    valid
}: Props) => {


    moment.locale("ja", {
        weekdays: [
          "日曜日",
          "月曜日",
          "火曜日",
          "水曜日",
          "木曜日",
          "金曜日",
          "土曜日",
        ],
        weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"],
      });


    //曜日を数字で返す
    const getWeekDayNumber = (current_day: number) => {

        let date:string = moment().year() + "/" + selectedMonth + "/" + current_day;
        return moment(date).weekday();
    
    };

    useEffect(() => {}, [times,months,days]);

    return (
        <div className="pt-20 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            {/* {alert.status && <Alert green>{alert.text}</Alert>} */}
            <Form onSubmit={() => false}>
                <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            予約受付
                        </h3>
                        <p className="mt-1 max-w-2xl text-xs text-gray-500">
                            項目を全部をご入力の上、次を押してください。
                        </p>
                    </div>
                    <div className="border-t border-gray-200">
                        <dl>
                            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                    お名前
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <input
                                        id="name"
                                        name="name"
                                        type="name"
                                        required
                                        className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300 py-2 px-2 border rounded outline-none"
                                        defaultValue={defaultValue.customerName}
                                        onChange={(event) => setCustomerName((event.target as HTMLInputElement).value)}
                                        placeholder="デモ 太郎"
                                    />
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                    メールアドレス
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <input
                                        id="name"
                                        name="name"
                                        type="name"
                                        required
                                        className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-r-md sm:text-sm border-gray-300 py-2 px-2 border rounded outline-none bg-white"
                                        defaultValue={defaultValue.email}
                                        onChange={(event) => setEmail((event.target as HTMLInputElement).value)}
                                        placeholder="demo@test.com"
                                    />
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-3 border-b pb-2 text-base items-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5 inline-flex mr-1"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                        />
                                    </svg>
                                    <span className="font-semibold">
                                        予約日程
                                    </span>
                                </dd>
                            </div>
                            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500 sm:grid-cols-1">
                                    日付選択
                                </dt>
                                <dd className="col-span-2 mt-1 flex sm:flex-row flex-col items-center justify-between sm:space-x-2 text-sm text-gray-900 sm:mt-0">
                                    <p className="mt-1 text-lg block text-semibold text-black">
                                        <span className="text-semibold sm:text-sm whitespace-nowrap">
                                            {this_year} 年
                                        </span>
                                    </p>
                                    <span> - </span>
                                    <select
                                        onChange={(event) =>
                                            setselectedMonth(event.target.value)
                                        }
                                        value={selectedMonth}
                                        name="month"
                                        id="month"
                                        className="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    >
                                        <option
                                            value=""
                                            defaultValue="true"
                                            hidden
                                        >
                                            -- 月を選択 --
                                        </option>
                                        {months.map((month, index) => {
                                            return (
                                                <option
                                                    key={index}
                                                    value={month}
                                                >
                                                    {month}月
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <span> - </span>
                                    <select
                                        onChange={(event) =>
                                            setselectedDay(event.target.value)
                                        }
                                        value={selectedDay}
                                        name="days"
                                        id="days"
                                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    >
                                        <option
                                            value=""
                                            defaultValue="true"
                                            hidden
                                        >
                                            -- 日を選択 --
                                        </option>
                                        {selectedMonth &&
                                            days.map((day, index) => 
                                                <option
                                                    key={index}
                                                    value={day}
                                                >
                                                    {day}日 (
                                                    {moment.weekdaysShort(
                                                        getWeekDayNumber(
                                                            day
                                                        )
                                                    )}
                                                    )
                                                </option>
                                            )}
                                    </select>
                                    <span>-</span>
                                    <select
                                        onChange={(event) =>
                                            setSelectedTime(event.target.value)
                                        }
                                        disabled={times.length ? false : true}
                                        value={selectedTime}
                                        name="times"
                                        id="times"
                                        autoComplete="country"
                                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    >
                                        <option
                                            value=""
                                            defaultValue="true"
                                            hidden
                                        >
                                            -- 時間帯を選択 --
                                        </option>
                                        {selectedMonth &&
                                            times.map((time, index) => {
                                                return (
                                                    <option
                                                        key={index}
                                                        value={time}
                                                    >
                                                        {time}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                </dd>
                            </div>
                            <dd className="px-4 pb-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                {valid && (
                                    <button
                                        onClick={() => setVerification(true)}
                                        className="bg-indigo-500 hover:bg-indigo-700 sm:w-auto w-full text-white px-4 py-2 rounded"
                                        type="button"
                                    >
                                        確認画面へ
                                    </button>
                                )}
                            </dd>
                        </dl>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default Reservation;
