import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from '../context/AuthContext';
import Cookies from 'js-cookie'
import { AnimatePresence, motion } from 'framer-motion';
import useClickAway from '../util/useClickAway';

export default function Header() {

    const { logged, setLogged } = useContext(AuthContext);
    const router = useHistory();
    const [mobileMenu, setMobileMenu] = useState(false);
    const moblieMenuRef = useRef<HTMLDivElement>(null);


    const userLogout = ()=>{

      setMobileMenu(false);
      Cookies.remove('authenticated');
      setLogged(false);
      router.push('/login');

    }

    useClickAway(
        moblieMenuRef,
        () => setMobileMenu(false)
    )

    useEffect(()=>{},[logged]);

    return (
        <nav className="bg-white fixed top-0 left-0 right-0 z-50">
            <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 relative z-50">
                <div className="relative flex items-center justify-between h-16">
                    <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
                        <button
                            onClick={() => setMobileMenu(!mobileMenu)}
                            className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                            aria-expanded="false"
                        >
                            <span className="sr-only">Open main menu</span>
                            {mobileMenu ? (
                                <svg
                                    className="h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    className="h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                            )}
                        </button>
                    </div>

                    <div className="flex-1 flex items-center justify-start sm:items-stretch sm:justify-start">
                        <div className="flex-shrink-0 flex items-center p-2 sm:p-0">
                            <img
                                className="block h-8 w-auto mr-2"
                                src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
                                alt="Workflow"
                            />
                            <h1 className="font-bold">予約管理</h1>
                        </div>

                        <div className="hidden sm:block sm:ml-6">
                            <div className="flex items-center h-full space-x-4">
                                <Link
                                    className="hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                    to="/"
                                >
                                    TOP
                                </Link>
                                {!logged ? (
                                    <Link
                                        className="hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                        to="/login"
                                    >
                                        管理者ログイン
                                    </Link>
                                ) : (
                                    <Link
                                        className="hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                        to="/dashboard"
                                    >
                                        ダッシュボード
                                    </Link>
                                )}
                            </div>
                        </div>
                        {   logged &&
                            <div className="py-1 ml-auto hidden sm:block">
                                <button
                                    onClick={userLogout}
                                    className="bg-red-500 text-white py-2 px-2 rounded-md text-sm font-medium "
                                >
                                    ログアウト
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <AnimatePresence>
                {mobileMenu && (
                    <motion.div
                        ref={moblieMenuRef}
                        initial={{ y: -10, opacity: 0, zIndex:40, position:'absolute',width: "100%" }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.35 , ease:'easeInOut' }}
                        className="bg-white shadow-md px-4 py-4 flex flex-col"
                    >
                        <div className="py-1 ml-auto">
                            <Link
                                onClick={() => setMobileMenu(false)}
                                className="text-gray-800  rounded-md text-sm font-medium inline"
                                to="/"
                            >
                                ホーム
                            </Link>
                        </div>
                        {!logged ? (
                            <div className="py-1 ml-auto">
                                <Link
                                    onClick={() => setMobileMenu(false)}
                                    className="text-gray-800  rounded-md text-sm font-medium"
                                    to="/login"
                                >
                                    管理者ログイン
                                </Link>
                            </div>
                        ) : (
                            <React.Fragment>
                                <div className="py-1 ml-auto">
                                    <Link
                                        onClick={() => setMobileMenu(false)}
                                        className="text-gray-800  rounded-md text-sm font-medium"
                                        to="/dashboard"
                                    >
                                        ダッシュボード
                                    </Link>
                                </div>
                                <div className="py-1 ml-auto">
                                    <button
                                        onClick={userLogout}
                                        className="text-red-500 py-2 rounded-md text-sm font-medium "
                                    >
                                        ログアウト
                                    </button>
                                </div>
                            </React.Fragment>
                        )}
                    </motion.div>
                )}
            </AnimatePresence>
        </nav>
    );
}
