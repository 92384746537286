import styled from 'styled-components'


interface TypeProp {
    readonly red?: boolean;
    readonly green?: boolean;
    readonly blue?: boolean;
};


const Label = styled.label`
    margin-right: 1rem;
`;
const FormRow = styled.div`
    margin: 1rem 0;
`;
const Form = styled.form`
    margin: 0 0;
`;

const Alert = styled.div<TypeProp>`
    padding: 20px;
    background-color: ${props => (props.red ? '#f44336' : props.green ? '#4CAF50' : props.blue ? 'skyblue' : 'gray')} ; /* Red */
    color: white;
    margin-bottom: 15px;
`;


export { Label, FormRow, Form, Alert };