import { useContext, useEffect, useState } from 'react'
import moment from 'moment';
import "moment/locale/pt-br";
import ReserveVerification from '../components/ReserveVerification';
import { registerLocale, setDefaultLocale }  from 'react-datepicker';
import ja from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import Reservation from '../components/Reservation';
import { AnimatePresence, motion } from 'framer-motion';
import { ReservationContext } from '../context/ReservationContext';
import { useHistory } from 'react-router';

registerLocale('ja', ja);
setDefaultLocale('ja');



export default function Home() {


  const this_year = new Date().getFullYear().toString();
  const [selectedMonth, setselectedMonth] = useState<any>('');
  const [selectedDay, setselectedDay] = useState<string>('');
  const [selectedTime, setSelectedTime] = useState<any>('');
  const [times, setTimes] = useState<Array<string>>([]);
  const [days, setDays] = useState<Array<any>>([]);
  const [months, setMonths] = useState<Array<any>>([]);
  const [verification, setVerification] = useState<boolean>(false);
  const [customerName, setCustomerName] = useState<string>('デモ 太郎');
  const [email, setEmail] = useState<string>('demo@demo.com');
  const { reservationState, setReservationState } = useContext(ReservationContext);
  const router = useHistory();


  //3つとも選択されたかどうかのチェック
  const valid = selectedMonth && selectedDay && selectedTime && customerName && email;
  // 今月
  const this_month = moment().month();
  //今日に日にち
  const this_day = moment().date();

  
  //営業時間
  const work_time = 8;
  //予約の間
  const reserve_time_interval = 60;
  //営業開始時
  const work_start_hour = 10;
  //営業開始分
  const work_start_minute = 0;
  // 予約開始可能な時間
  const work_start_time = moment().hour(work_start_hour).minute(work_start_minute);


  const initState = () => {
    setVerification(false);
    setselectedMonth('');
    setselectedDay('');
    setSelectedTime('');
  }

  const complete = (full_date:string, name:string = "デモ 太郎", email:string　= "demo@demo.com") => {

    initState();

    setReservationState(
      {
        ...reservationState,
        status: true,
        content: {
          ...reservationState.content,
          date: full_date,
          name,
          email,
        }
      }
    );

    router.push('/success');

  }

  const generateTImes = () => {

        // 時間帯を作る
        let tms:Array<any> = [];
        
        //時間帯作成する
        for (let i = 0; i < work_time; i++) {
          tms.push(work_start_time.add(reserve_time_interval,'minute').format('HH:mm').toString())
        }
    
        //先頭に始めの時間帯追加してあげる
        tms.unshift(moment().hour(work_start_hour).minute(work_start_minute).format('HH:mm').toString());
        setTimes([...tms]);

  }

  const generateMonths = () => {
        //月を作る
        let mn:Array<any> = [];
        for (let i = (this_month + 1); i <= 12; i++) mn.push(i);
        setMonths([...mn]);
  }


  console.log(months);
  console.log(times);


  const selectedMonthChange = (current_month: string) => {

    //曜日を数字で返す
    const getWeekDayNumber = (current_day: number) => {

      let date:string = moment().year() + "/" + current_month + "/" + current_day;
      return moment(date).weekday();

    };

    //週末判別
    const isWeekend = (current_day: number) => {
      return getWeekDayNumber(current_day) === 0 ||
          getWeekDayNumber(current_day) === 6
          ? true
          : false;
    };

    //日数を作る
    //過去の日付が表示されるないように今日からカウント始めるようにする
    const start_date = current_month === (moment().month() + 1).toString() /*今月だった場合*/ ? this_day : 1

    
    let days_count:number = (moment().month(Number(current_month) - 1).daysInMonth() + 1)/*今月の日数 */;
    let dy:Array<any> = [];

    for (let i = start_date; i < days_count; i++){

      !isWeekend(i) && dy.push(i);

    } 


    setSelectedTime('');
    setselectedDay('');
    setDays([...dy]);
    setselectedMonth(current_month);



  }




  useEffect(()=>{
    
    generateMonths();
    generateTImes();

    
  },[]);

  

  const checkTodayDate = (current_selected_day:string) => {

    
      // もし日にちが今日を選択された場合
      const selected_date = moment().year(Number(this_year)).month(selectedMonth).day(current_selected_day);
      const today_date = moment().year(Number(this_year)).month(selectedMonth).day(this_day);


      if(moment(selected_date).isSame(today_date, 'day')) {
  
        
  
          // 当てはまる時間帯を探す
          let today_times:Array<string> = times.filter(time => moment().isBefore(
            moment(this_year + "/" + selectedMonth + "/" + current_selected_day + " " + time)
          ));


          //新しい時間帯を格納
          setTimes([...today_times]); 
          console.log("today_times",today_times);    
        
      }

      setselectedDay(current_selected_day);


  }


  //確認画面
    return (
      <AnimatePresence>
        <motion.div
          key="home"
          initial={{ y: -30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -30, opacity: 0, position:'absolute',width: '100%'}}
          transition={{ duration: 0.5 }}
        >
            <AnimatePresence initial={false}>
                {!verification ? (
                    <motion.div
                      key="first"
                      initial={{ x: -300, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ x: -300, opacity: 0, position:'absolute',width: '100%'}}
                      transition={{ duration: 0.35 }}
                    >
                        <Reservation
                            days={days}
                            months={months}
                            times={times}
                            defaultValue={{
                              email,
                              customerName
                            }}
                            selectedDay={selectedDay}
                            selectedMonth={selectedMonth}
                            selectedTime={selectedTime}
                            this_year={this_year}
                            setCustomerName={(value: string) => setCustomerName(value)}
                            setEmail={(value:string) => setEmail(value)}
                            setselectedDay={(value: any) =>
                                checkTodayDate(value)
                            }
                            setSelectedTime={(value: any) =>
                                setSelectedTime(value)
                            }
                            setselectedMonth={(value: string) =>
                                selectedMonthChange(value)
                            }
                            setVerification={(value: boolean) =>
                                setVerification(value)
                            }
                            valid={valid}
                        />
                    </motion.div>
                ) : (
                    <motion.div
                        key="last"
                        initial={{ x: 300, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: 300, opacity: 0, position:'absolute', width: '100%' }}
                        transition={{ duration: 0.35 }}
                    >
                        <ReserveVerification
                            selectedDay={selectedDay}
                            selectedMonth={selectedMonth}
                            selectedTime={selectedTime}
                            setVerification={(value) => setVerification(value)}
                            customerName={customerName}
                            email={email}
                            thisYear={this_year}
                            complete={(full_date:string) => complete(full_date)}
                        />
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.div>
      </AnimatePresence>
    );
  
}
