import { ReservationStateContentType } from "../context/ReservationContext";
import { checkAuth } from "./auth";

export type ReservationsType = Array<ReservationStateContentType>;

export const saveReservation = (data: ReservationStateContentType) => {
    if (localStorage.getItem("reservation")) {
        let reser_ary: ReservationsType = JSON.parse(
            localStorage.getItem("reservation") || "[]"
        );

        reser_ary.push({
            ...data,
            ...data.date,
        });

        localStorage.setItem("reservation", JSON.stringify(reser_ary));
    } else {
        localStorage.setItem(
            "reservation",
            JSON.stringify([
                {
                    ...data,
                    ...data.date,
                },
            ])
        );
    }
};
export const getReservation = () => {
    try {
    
        if (checkAuth()) {
            return localStorage.getItem("reservation");
        }

        throw new Error("権限がありません、先にログインしてください");
    
    } catch (error) {

        throw error;

    }
};

export const removeReservation = (target: ReservationStateContentType) => {

    let reservation_list = JSON.parse(
        localStorage.getItem("reservation") || "[]"
    );

    localStorage.setItem(
        "reservation",
        JSON.stringify(
            reservation_list.filter(
              (item: ReservationStateContentType) => JSON.stringify(item) !== JSON.stringify(target)
            )
        )
    );
};
