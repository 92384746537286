import { AnimatePresence, motion } from 'framer-motion';
import { ReactElement, ReactNode, useRef} from 'react'
import { createPortal } from 'react-dom'
import { CheckSvg, TimesSvg } from '../svgs';
interface Props {
    cancel: Function;
    ok: Function;
    backdropClose?:boolean;
    children: ReactNode;
    title:string;
    open: boolean;
    modalVariant:string;
    size:string
}


function Modal({children,title,cancel,ok,open,backdropClose = true, modalVariant = "bg-gray-100", size = "lg"}: Props): ReactElement {

    const modal_body = useRef<HTMLDivElement | null>(null);

    let withCLass;

    switch (size) {
        case "lg":
            withCLass = "sm:max-w-3xl";
            break;
        case "sm":
            withCLass = "sm:max-w-lg";
            break;
        case "md" :
            withCLass = "sm:max-w-xl";
            break;

        case "xl" :
            withCLass = "sm:max-w-4xl";
            break;

        case "full" :
            withCLass = "w-full h-full flex flex-col";
            break;
        default:
            withCLass = ""
            break;
    }


        const modal = <AnimatePresence>
            {
                open && 
                <motion.div initial={{ y:-10, opacity: 0  }}
                    animate={{ y:0, opacity: 1  }}
                    transition={{ duration: 0.35  }}
                    exit={{ y:-10, opacity: 0  }}
                    className="fixed z-50 inset-0">
                    <div className="back-drop fixed inset-0 bg-gray-100 opacity-75 z-40" onClick={() => backdropClose && cancel()}></div>
                    <div className={"absolute border bg-white shadow-xl rounded top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 w-11/12 " + withCLass}>
                        <div className={"title p-4 rounded-t " + modalVariant}>
                            <h1 className="text-semibold">{title}</h1>
                        </div>
                        <div ref={modal_body} style={{
                            flexGrow:  size==="full" ? 1 : 0
                        }} className="modal-body p-4">{children}</div>
                        <div className="footer bg-gray-100">
                            <div className=" grid grid-cols-2 gap-4 p-4">
                                <button
                                    onClick={() => cancel()}
                                    className="items-center col-span-1 sm:mb-0 mb-2 sm:text-base text-xs bg-gray-300 hover:bg-gray-400 hover:text-gray-100 sm:w-auto w-full text-gray-500 px-4 py-2 rounded"
                                >
                                    <TimesSvg />
                                    キャンセル
                                </button>
                                <button
                                    onClick={() => ok()}
                                    className="items-center col-span-1 sm:mb-0 mb-2 sm:text-base text-xs bg-indigo-500 hover:bg-indigo-700 sm:w-auto w-full text-white px-4 py-2 rounded"
                                >
                                    <CheckSvg />
                                    確定
                                </button>
                            </div>
                        </div>
                    </div>
                </motion.div>
            }
        </AnimatePresence>

    return createPortal(
        modal,
        document.querySelector("#modal-root") as HTMLDivElement
    );
}

export default Modal
