import React, { useRef } from 'react'
import { saveReservation } from '../util/reservationController'
import Alert from '../components/Alert';
import { CheckSvg, LeftArrowSvg } from '../svgs';

interface Props {
  thisYear:string
  selectedMonth:string
  selectedDay:string
  selectedTime:string
  setVerification: React.Dispatch<React.SetStateAction<boolean>>
  complete: Function
  customerName:string;
  email:string
}

export default function ReserveVerification({
    thisYear,
    selectedMonth,
    selectedDay,
    selectedTime,
    setVerification,
    customerName,
    email,
    complete
}:Props) {


    const commitReservation = () => {
      
      saveReservation({
          name: customerName,
          email,
          date: {
              year: thisYear,
              month: selectedMonth,
              day: selectedDay,
              time: selectedTime
          }
      });

      const full_date = thisYear + "年" + selectedMonth + "月" + selectedDay + "日" + selectedTime;

      complete(full_date);

    }

    const alertRef = useRef<any>(null);

    return (
        <div className="pt-20 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div ref={alertRef} className="mb-4">
              <Alert
                
                text="以下の内容で間違い無いですか？"
                type="warning"
                removeAlret={()=> alertRef.current.remove()}
              />
            </div>

            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        予約受付
                    </h3>
                    <p className="mt-1 max-w-2xl text-xs text-gray-500">
                        下記の内容をご確認の上、確定ボタンを押してください！
                    </p>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                お名前
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span>{customerName}</span>
                            </dd>
                        </div>
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                メールアドレス
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {email}
                            </dd>
                        </div>
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 border-b pb-2 text-base items-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5 inline-flex mr-1"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                    />
                                </svg>
                                <span className="font-semibold">予約日程</span>
                            </dd>
                        </div>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500 sm:grid-cols-1">
                                日付選択
                            </dt>
                            <dd className="col-span-2 mt-1 flex sm:flex-row flex-col sm:items-center justify-between sm:space-x-2 text-sm text-gray-900 sm:mt-0">
                                <p className="mt-1 text-lg block text-semibold text-gray-500">
                                    <span className="text-semibol">
                                        {thisYear}
                                    </span>
                                    <span> 年 </span>
                                      {selectedMonth}
                                    <span> 月 </span>
                                      {selectedDay}
                                    <span> 日 </span>
                                      {selectedTime}
                                    <span> 時 </span>
                                </p>
                            </dd>
                        </div>
                        <dd className="px-4 pb-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                            <button
                                className="sm:col-span-1 col-span-2 sm:mb-0 mb-2 bg-gray-300 hover:bg-gray-400 hover:text-gray-100 sm:w-auto w-full text-gray-500 px-4 py-2 rounded"
                                onClick={() => setVerification(false)}
                            >
                                <LeftArrowSvg/>
                                戻る
                            </button>
                            <button
                                className="sm:col-span-1 col-span-2 sm:mb-0 mb-2 bg-indigo-500 hover:bg-indigo-700 sm:w-auto w-full text-white px-4 py-2 rounded"
                                onClick={commitReservation}
                            >
                                <CheckSvg/>
                                確定
                            </button>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    );
}
