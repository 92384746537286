import { useContext } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';
import { checkAuth } from '../util/auth';
import Home from '../views/Home';
import Login from '../views/Login';
import Dashboard from '../views/Dashboard';
import { ReservationContext } from '../context/ReservationContext';
import Success from '../views/Success';
import { AnimatePresence } from 'framer-motion';

export default function Main() {

    const { reservationState } = useContext(ReservationContext);

    return (
        <main className="px-2 sm:px-6 lg:px-8">
            <AnimatePresence exitBeforeEnter >
                <Switch >
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route path="/login">
                        {checkAuth() ? (
                            <Redirect to="/dashboard" />
                        ) : (
                            
                                <Login />
                        )}
                    </Route>
                    <Route path="/dashboard">
                        {checkAuth() ? <Dashboard /> : <Redirect to="/login" />}
                    </Route>
                    <Route path="/success">
                        {reservationState.status ? (
                            <Success />
                        ) : (
                            <Redirect to="/" />
                        )}
                    </Route>
                </Switch>
            </AnimatePresence>
        </main>
    );
}
