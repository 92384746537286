import { useEffect } from "react";

export default function useClickAway(ref:React.RefObject<HTMLDivElement>, callBack: Function) {
    /**
 * Hook that alerts clicks outside of the passed ref
 */
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event:MouseEvent) {
            if (ref.current && !ref.current.contains(event.target as Element)) {
                callBack();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, callBack]);
};
