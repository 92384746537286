import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import Cookies from 'js-cookie';
import { AuthContext } from '../context/AuthContext'
import Alert from "../components/Alert";
import { motion } from "framer-motion";

export default function Login() {

    const [validationError, setValidationError] = useState<null | string>(null);
    const router = useHistory();
    const { setLogged } = useContext(AuthContext);

    const userLogin = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        const target = e.target as HTMLFormElement;

        if (target.email.value === 'demo@demo.com' && target.password.value === 'demo') {

            Cookies.set('authenticated', 'true');
            setLogged(true);
            router.push('/dashboard');

        } else {

            setValidationError('ログイン情報が間違っています');

        }

    }

    return (
        <motion.div
            key="login-page"
            initial={{ y: -30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -30, opacity: 0, position:'absolute',width: '100%'}}
            transition={{ duration: 0.5 }}
            className="min-h-screen flex items-center justify-center px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                

                <div>
                    <img
                        className="mx-auto h-12 w-auto"
                        src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                        alt="Workflow"
                    />
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        管理者へログイン
                    </h2>
                    <p className="mt-2 text-center text-xs text-gray-600">
                        ログインして機能を試す
                    </p>
                </div>
                {
                    validationError && 

                    <Alert
                        text={ validationError }
                        removeAlret={ () => setValidationError('') }
                        type="error"
                    />
                }
                <form
                    onSubmit={userLogin}
                    className="mt-8 space-y-6"
                    action="#"
                    method="POST"
                >
                    <div className="rounded-md shadow-sm -space-y-px">
                        <div>
                            <label htmlFor="email-address" className="sr-only">
                                Email address
                            </label>
                            <input
                                id="email-address"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                defaultValue={"demo@demo.com"}
                                placeholder="demo@demo.com"
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className="sr-only">
                                Password
                            </label>
                            <input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                required
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                defaultValue={"demo"}
                                placeholder="demo"
                            />
                        </div>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <input
                                id="remember_me"
                                name="remember_me"
                                type="checkbox"
                                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                            />
                            <label
                                htmlFor="remember_me"
                                className="ml-2 block text-sm text-gray-900"
                            >
                                次回から入力しない
                            </label>
                        </div>
                        <div className="text-sm">
                            <a
                                href="/login"
                                className="font-medium text-indigo-600 hover:text-indigo-500"
                            >
                                パスワードお忘れですか?
                            </a>
                        </div>
                    </div>
                    <div>
                        <button
                            type="submit"
                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                {/* Heroicon name: lock-closed */}
                                <svg
                                    className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </span>
                            ログインする
                        </button>
                    </div>
                </form>
            </div>
        </motion.div>
    );
}
