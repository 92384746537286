import { useEffect, useState } from 'react'
import { removeReservation, ReservationsType } from '../util/reservationController';
import CustomTable from '../components/CustomTable';
import Modal from '../components/Modal';
import { ReservationStateContentType } from '../context/ReservationContext';
import { motion } from "framer-motion";

const defaultTargetReservation = {name:'',email:'', date:{
    month: '',
    day: '',
    time: '',
    year: ''
}}

export default function Dashboard() {

    const [reservationList, setReservationList] = useState<ReservationsType>([]);
    const [modal, setModal] = useState(false);
    const [targetReservation, setTargetReservation] = useState<ReservationStateContentType>(defaultTargetReservation);
    
    const getReservationList = () => {
        const list:ReservationsType = JSON.parse(localStorage.getItem('reservation') || '[]');
        return list;
    }

    const removeList = (arry_item?: ReservationStateContentType) => {

        if(arry_item){

            setModal(true);
            setTargetReservation(arry_item);

            return;
        }

        setModal(false);
        setReservationList([...reservationList.filter(item => item !== targetReservation)]);
        removeReservation(targetReservation);

    }

    
    useEffect(() => {
        
        setReservationList(getReservationList());

    }, [])

    return (
        <motion.div
            key="dashboard"
            initial={{ y: -30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -30, opacity: 0, position:'absolute',width: '100%'}}
            transition={{ duration: 0.5 }}
            className="mt-20 max-w-7xl mx-auto sm:px-6 px-3">
            <div className="title sm:my-10 my-5 text-center">
                <h1 className="font-bold sm:text-2xl text-xl">ダッシュボード</h1>
            </div>
            <Modal 
                title="注意"
                open={modal}
                ok={ () => removeList() }
                cancel={ () => setModal(false) }
                modalVariant="bg-red-500 text-white"
                size="sm"
            >
                <p>
                    本当に削除しますか？
                </p>

            </Modal>
            <CustomTable removeList={(data:ReservationStateContentType) => removeList(data)} valueArray={ reservationList }/>
        </motion.div>
    )
}
