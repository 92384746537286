import { createContext } from 'react'

export type ReservationContextInterface = {
    reservationState: ReservationStateType;
    setReservationState: React.Dispatch<React.SetStateAction<object>> | Function;
}

export type ReservationStateType = {
    status: boolean;
    content?: ReservationStateContentType;
}
export type ReservationStateContentType = {
    name:string;
    email:string;
    date: ReservationStateContentDateType;
}
export type ReservationStateContentDateType = {
    year:string;
    month:string;
    day:string;
    time:string;
}

const reservationState = {
    status: false,
}

export const ReservationContext = createContext<ReservationContextInterface>({ reservationState, setReservationState: ():void => undefined})