import { motion } from 'framer-motion'
import { ReactElement, useContext } from 'react'
import { ReservationContext } from '../context/ReservationContext'

function Success(): ReactElement {

    const { reservationState } = useContext(ReservationContext);


    return (
        <motion.div
            initial={{ opacity:0, y: -30 }}
            animate={{ opacity:1, y: 0 }}
            transition={{ duration: .5, easings: ["circIn"] }}
            className="mt-20 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8"
        >   
            <div className="title mb-6 text-center bg-green-300 p-2 rounded">
                <h1 className="text-base font-semibold">
                    ご予約ありがとうございました！🎉
                </h1>
                <p className="text-xs">
                    以下の内容で受付致しました、<br/>
                    当日お待ちしております。
                </p>
            </div>
            <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                お名前
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span>{reservationState.content?.name || ""}</span>
                            </dd>
                        </div>
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                メールアドレス
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {reservationState.content?.email || ""}
                            </dd>
                        </div>
                        
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500 sm:grid-cols-1">
                                日付選択
                            </dt>
                            <dd className="col-span-2 mt-1 flex sm:flex-row flex-col sm:items-center justify-between sm:space-x-2 text-sm text-gray-900 sm:mt-0">
                                <p className="mt-1 text-lg block text-semibold text-gray-500">
                                    <span className="text-semibol">
                                        {reservationState.content?.date}
                                    </span>
                                </p>
                            </dd>
                        </div>
                    </dl>
                </div>
            
        </motion.div>
    )
}

export default Success
