import {
   Router,
} from "react-router-dom";
import Header from './layouts/Header';
import Main from './layouts/Main';
import {useEffect, useRef, useState} from 'react';
import { checkAuth } from './util/auth';
import { AuthContext } from './context/AuthContext';

import { createBrowserHistory } from "history";
import { ReservationContext, ReservationStateType } from "./context/ReservationContext";
const routeHistory = createBrowserHistory();

interface Window { gtag(set1:string, trackid:string, config:{}): void; }
declare var window: Window;

function App() {
  
  const [logged, setLogged] = useState(checkAuth());
  const [reservationState, setReservationState] = useState<ReservationStateType>({ status: false });


  const router = useRef(null);

  useEffect(() => {
    routeHistory.listen((location) => {
      window.gtag('config', 'G-J7RK8B1XJV', {
        'page_path': location.pathname
      });
    });
  });


  return (
      <AuthContext.Provider value={{ logged, setLogged }}>
        <ReservationContext.Provider value={{ reservationState, setReservationState}}>
          <Router ref={router} history={routeHistory}>
            <Header/>
            <Main/>
          </Router>
        </ReservationContext.Provider>
      </AuthContext.Provider>
  );
}

export default App;
